import logo from './logo.svg';
import './App.css';
import "./styles/font.css"
import tab from "./assets/Speed Tab.jpg"
import mob from "./assets/Speed Mob.jpg"
import web from "./assets/Speeed.jpg"
import Navbar from './components/Header/Navbar';
import Footer from './components/footer/footer';
import AllRoutes from './routes/AllRoutes';
import AOS from 'aos';
import Spacer from './components/common/Spacer';
// import "aos/dis/aos.css"



function App() {

    AOS.init({
      duration:2000
    });
  return (
    <div className="App">
      {/* {
        window.innerWidth>1000?<>
        <Navbar/>
        <AllRoutes/>
         <Footer/>
        <img style={{ width:"100%",objectFit:"contain" }} src={web}/>
        </>:window.innerWidth>700?<><img style={{ width:"100%",objectFit:"contain" }} src={tab}/></>:<><img style={{ width:"100%",objectFit:"contain" }} src={mob}/></>
      } */}
      <Navbar/>
        <AllRoutes/>
        {
          window.location.pathname=="/help"?<></>:<Spacer height='2rem'/>
        }
        
         <Footer/>
      
    </div>
  );
}

export default App;
