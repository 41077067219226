import React from "react";
import { Route,  Routes } from "react-router-dom";
import Home from "../pages/Home";
import WhySpeed from "../pages/whySpeed";
import IntelligencePlatform from "../pages/IntellengencePlatform";
import PerformanceOpt from "../pages/PerformanceOpt";
import PerformanceCandD from "../pages/PerformanceC&D";
import Sports from "../pages/Sports";
import Defence from "../pages/Defence";
import Explore from "../pages/Explore";
import Contact from "../pages/Contact";
import ScrollToTop from "../components/common/ScrollToTop";
import Help from "../pages/Help";




        
const AllRoutes = () => {

    return (
        <div style={{ maxWidth:"1480px",margin:"auto",height:"100%",position:"relative",width:"100%" }}>
        
        <ScrollToTop/>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/whySpeed" element={<WhySpeed/>} />
            <Route path="/intell/performance/med" element={<IntelligencePlatform/>} />
            {/* <Route path="/intell/performance/med" element={<PerformanceMed/>} /> */}
            <Route path="/intell/performance/opt" element={<PerformanceOpt/>} />
            <Route path="/intell/performance/c&d" element={<PerformanceCandD/>} />
            <Route path="/sports" element={<Sports/>} />
            <Route path="/defence" element={<Defence/>} />
            <Route path="/explore" element={<Explore/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route  path="/help" element={<Help/>} />
            <Route   path="*" element={<Home/>} />





            {/* <Route path="/research" element={<Research/>} />
            <Route path="/career" element={<Career/>} />
            <Route path="/voice" element={<VoiceToText/>} /> */}

        </Routes></div>
    );
};
export default AllRoutes;