import React from "react";
const Help= () => {
    return (
        
        <iframe
        style={{ height:"75vh",overflow:"hidden",marginTop:"3%" }}
        width="100%"
        // height="80%"
        src="https://saideveloper.physioai.care/support"
        frameBorder="0"
        title="Embedded Support Page"
        allowFullScreen
      />
     
    );
};

export default Help;