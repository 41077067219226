import React from "react";
import styles from "../../styles/main.module.css";
import logo from "../../assets/speed logo.png";
import { redirect, useNavigate } from "react-router-dom";
// import Drawer from "react-modern-drawer";
import Drawer from "react-modern-drawer";
import 'react-modern-drawer/dist/index.css'
import { useMediaQuery } from "react-responsive";

const Navbar = () => {
  const isMobielOrTab = useMediaQuery({ maxWidth: "900px" });
  const navigate = useNavigate();

  return (
    <div className={`${styles?.["flex-between"]} ${styles?.["navbar"]}`}>
      <div
        style={{ gap: "4rem" }}
        className={`${styles?.["flex-gap"]} ${styles?.["w-60"]}`}
      >
        <div
          style={{ padding: "0rem 1rem", height: "2.5rem" }}
          onClick={() => navigate("/")}
          className={`${styles?.["flex-gap"]}  ${styles?.["pointer"]} ${styles?.["white-background"]} ${styles?.["secondary-color"]}    `}
        >
          <div style={{ width: "20px" }}>
            <img style={{ width: "100%" }} src={logo} alt="" />
          </div>
          <div
          style={{ fontStyle:"italic" }}
            className={` ${styles?.["head-sm"]} ${styles?.["secondary-color"]}`}
          >
            SPEEED.AI
          </div>
        </div> 
        {!isMobielOrTab ? (
          <div className={`${styles?.["flex-between"]} ${styles?.["w-80"]}`}>
            <div
              onClick={() => navigate("/whyspeed")}
              className={` ${styles?.["pointer"]} ${styles?.["head-sm"]}  ${
                window.location.pathname != "/whyspeed"
                  ? styles?.["tab"]
                  : styles?.["active-tab"]
              } `}
            >
              Why Speeed.AI
            </div>
            <div className={`${styles?.["dropdown"]}`}>
              <div
                // onClick={() => navigate("/intell/platform")}
                className={` ${styles?.["pointer"]}  ${styles?.["head-sm"]}  ${
                  window.location.pathname == "/intell/platform" ||
                  window.location.pathname == "/intell/performance/opt" ||
                  window.location.pathname == "/intell/performance/med" ||
                  window.location.pathname == "/intell/performance/c&d"
                    ? styles?.["active-tab"]
                    : styles?.["tab"]
                } `}
              >
                {" "}
                Intelligence Platform
              </div>
              <div className={`${styles?.["dropdown-content"]}`}>
                <div
                  onClick={() => navigate("/intell/performance/med")}
                  className={` ${
                    window.location.pathname == "/intell/performance/med"
                      ? styles?.["dropdown-item-active"]
                      : styles?.["dropdown-item"]
                  } `}
                >
                  Performance Medicine
                </div>
                <div
                  onClick={() => navigate("/intell/performance/opt")}
                  className={`${
                    window.location.pathname == "/intell/performance/opt"
                      ? styles?.["dropdown-item-active"]
                      : styles?.["dropdown-item"]
                  } `}
                >
                  Performance Optimization
                </div>
                <div
                  onClick={() => navigate("/intell/performance/c&d")}
                  className={`${
                    window.location.pathname == "/intell/performance/c&d"
                      ? styles?.["dropdown-item-active"]
                      : styles?.["dropdown-item"]
                  } `}
                >
                  Coaching & Development
                </div>
              </div>
            </div>
            <div
              onClick={() => navigate("/sports")}
              className={` ${styles?.["pointer"]} ${styles?.["head-sm"]}  ${
                window.location.pathname != "/sports"
                  ? styles?.["tab"]
                  : styles?.["active-tab"]
              } `}
            >
              {" "}
              Sports
            </div>
            <div
              onClick={() => navigate("/defence")}
              className={` ${styles?.["pointer"]} ${styles?.["head-sm"]}  ${
                window.location.pathname != "/defence"
                  ? styles?.["tab"]
                  : styles?.["active-tab"]
              } `}
            >
              {" "}
              Defence
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className={`${styles?.["flex-gap"]} ${styles?.["head-sm"]} `}>
        {/* <span className={` ${styles?.["pointer"]}`}>Login</span> */}
        <button
        style={{ fontFamily:"Bebas Neue, ans-serif",letterSpacing:"1px",fontSize:"1rem" }}
          onClick={() => navigate("/contact")}
          className={`${styles?.["white-button"]} ${styles?.["white-button"]} ${styles?.["primary-color"]} ${styles?.["req-button"]}`}
        >
          Contact Us
        </button>
      </div>
      {isMobielOrTab ? <Hamburger /> : <></>}
    </div>
  );
};
export default Navbar;
const Hamburger = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <div style={{ marginLeft: "1rem" }} onClick={toggleDrawer}>
        <svg
          width="25"
          height="25"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 16V13.3333H20V16H0ZM0 9.33333V6.66667H20V9.33333H0ZM0 2.66667V0H20V2.66667H0Z"
            fill="white"
          />
        </svg>
      </div>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className={styles.menus}
      >
        <div className={`${styles?.["mainMenu"]} ${styles?.["head-md"]} `}>
          Main Menu
        </div>
        <hr />

        <div
          onClick={() => navigate("/")}
          className={` ${styles?.["pointer"]}  ${
            window.location.pathname != "/"
              ? styles?.["menu"]
              : styles?.["menuActive"]
          } `}
        >
          Home
        </div>
        <div
              onClick={() => navigate("/whyspeed")}
              className={` ${styles?.["pointer"]}   ${
                window.location.pathname != "/whyspeed"
                  ? styles?.["menu"]
                  : styles?.["menuActive"]
              } `}
            >
              Why Speeed.AI
            </div>
            <div className={`${styles?.["dropdown"]}`}>
              <div
                // onClick={() => navigate("/intell/platform")}
                className={` ${styles?.["pointer"]}    ${
                  window.location.pathname !== "/intell/platform" ||
                  window.location.pathname !== "/intell/performance/opt" ||
                  window.location.pathname !== "/intell/performance/med" ||
                  window.location.pathname !== "/intell/performance/c&d"
                  ? styles?.["menu"]
                  : styles?.["menuActive"]
                } `}
              >
                {" "}
                Intelligence Platform
              </div>
              <div className={`${styles?.["dropdown-content"]}`}>
                <div
                  onClick={() => navigate("/intell/performance/med")}
                  className={` ${
                    window.location.pathname !== "/intell/performance/med"
                    ? styles?.["menu"]
                    : styles?.["menuActive"]
                  } `}
                >
                  Performance Medicine
                </div>
                <div
                  onClick={() => navigate("/intell/performance/opt")}
                  className={`${
                    window.location.pathname !== "/intell/performance/opt"
                    ? styles?.["menu"]
                    : styles?.["menuActive"]
                  } `}
                >
                  Performance Optimization
                </div>
                <div
                  onClick={() => navigate("/intell/performance/c&d")}
                  className={`${
                    window.location.pathname !== "/intell/performance/c&d"
                    ? styles?.["menu"]
                    : styles?.["menuActive"]
                  } `}
                >
                  Coaching & Development
                </div>
              </div>
            </div>
            <div
              onClick={() => navigate("/sports")}
              className={` ${styles?.["pointer"]}   ${
                window.location.pathname != "/sports"
                ? styles?.["menu"]
                : styles?.["menuActive"]
              } `}
            >
              {" "}
              Sports
            </div>
            <div
              onClick={() => navigate("/defence")}
              className={` ${styles?.["pointer"]}  ${
                window.location.pathname != "/defence"
                ? styles?.["menu"]
                : styles?.["menuActive"]
              } `}
            >
              {" "}
              Defence
            </div>

        <div
          onClick={() => navigate("/contact")}
          className={` ${styles?.["pointer"]}  ${
            window.location.pathname != "/contact"
            ? styles?.["menu"]
            : styles?.["menuActive"]
          } `}
        >
          Contact Us
        </div>
      </Drawer>
    </>
  );
};
